import Modal from 'react-modal';
import { useRouter } from 'next/router';
import Cookies from 'js-cookie';
import Image from 'next/image';

import colours from '@common/colours';
import { NavigationButton } from '@common/buttons';
import { H3, P } from '@common/textElements';
import {
  getLocaleFromCountryCode,
  REGIONS,
} from '@constants/internationalConstants';
import { cera, firsNeue } from '@pages/_app';
import { COUNTRY_CODE_EMOJI_MAP } from '../../nextConstants/countryConstants';
import { BodyRegular } from '../../common/body';
import UnderlinedLink from '../UnderlinedLink';

import cancelCross from '@public/img/icons/cancel-cross.png';

type Props = {
  isModalOpen: boolean;
  onRequestClose: () => void;
};

const modalStyle = {
  content: {
    width: '100%',
    maxWidth: '500px',
    left: '50%',
    right: '50%',
    transform: 'translate(-50%, -50%)',
    bottom: 'initial',
    top: '50%',
    padding: '42px 40px',
    overflow: 'auto',
    border: 'none',
    height: 'auto',
    maxHeight: '80%',
  },
  overlay: {
    backgroundColor: `${colours.snBlack}80`,
    zIndex: 9999,
  },
};

const CountrySelectorModal = ({
  isModalOpen,
  onRequestClose,
}: Props): JSX.Element => {
  const { locale, asPath } = useRouter();

  const onClickCountry = (countryCode: string) => {
    const newLocale = getLocaleFromCountryCode(countryCode);
    if (newLocale === locale) {
      onRequestClose();
      return;
    }

    // Set the new locale as a Cookie for 365 days
    Cookies.set('NEXT_LOCALE', newLocale as string, {
      expires: 365,
      path: '/',
    });
    if (window.mixpanel?.track) {
      window.mixpanel.track('Selected new country', {
        From: locale,
        To: newLocale,
        'Current page': asPath,
      });
    }
    window.location.reload();
  };

  // Filter out 'unknown' and sort the list so that Europe appears at the top
  const regionList = REGIONS.filter(region => region.name !== 'unknown').sort(
    region => (region.name === 'europe' ? -1 : 1),
  );

  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={onRequestClose}
      style={modalStyle}
      contentLabel="Country Selector"
      closeTimeoutMS={150}
    >
      {/* This applies two font variables - and then sets a default page font of Cera Pro.
          For modals, we have to apply the font variables at the top of the modal -
          as they sit outside of the DOM nesting of the rest of the app */}
      <div className={`${cera.variable} ${firsNeue.variable} font-cera`}>
        <button
          className="absolute right-[15px] top-[15px]"
          onClick={onRequestClose}
          type="button"
        >
          <Image src={cancelCross} alt="Cancel" width={30} height={30} />
        </button>
        <H3 textAlign="center">Select your country</H3>
        {regionList.map(region => (
          <div className="px-[30px]" key={region.name}>
            <P fontSize={16} marginTop={40} fontWeight={900}>
              {region.readableName}
            </P>
            <ul>
              {(region.countryCodes as readonly string[]).map(countryCode => (
                <li
                  key={countryCode}
                  className="mb-[20px] text-blue hover:text-blue-600"
                >
                  <button
                    onClick={() => onClickCountry(countryCode)}
                    type="button"
                  >
                    <BodyRegular>
                      {
                        COUNTRY_CODE_EMOJI_MAP[
                          countryCode as keyof typeof COUNTRY_CODE_EMOJI_MAP
                        ].name
                      }
                    </BodyRegular>
                  </button>
                </li>
              ))}
            </ul>
          </div>
        ))}
        <P fontSize={13} marginTop={40} marginBottom={40}>
          Is your country not listed? You can still join the programme, but be
          sure to email our support team once you{"'"}ve signed up at{' '}
          <UnderlinedLink href="mailto:support@secondnature.io">
            support@secondnature.io
          </UnderlinedLink>
          !
        </P>
        <div className="text-center">
          <NavigationButton width="157px" onClick={onRequestClose}>
            Close
          </NavigationButton>
        </div>
      </div>
    </Modal>
  );
};

Modal.setAppElement('body');

CountrySelectorModal.defaultProps = {
  isModalOpen: false,
};

export default CountrySelectorModal;
